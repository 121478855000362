import { ConfirmingPortfolio } from '@fingo/lib/views';
import React from 'react';

const PendingConfirming = () => (
  <ConfirmingPortfolio
    viewTitle="Nóminas en curso"
    queryCustomVariables={
      { status_Status: 'INITIATED' }
    }
    includeHeaders={
      ['id',
        'createdAt',
        'totalAmount',
        'numberOfOperations',
        'numberOfDocumentsWithCessionInfo',
        'cessionStatus',
        'mailReminder',
      ]
    }
    collapsibleHeaders={
      ['company',
        'totalAmount',
        'numberOfDocumentsWithCessionInfo',
        'cessionStatus',
        'mailReminder',
      ]
    }
    collapsibleSubHeaders={
      [
        'folio',
        'dateIssued',
        'expirationDate',
        'amountWithIva',
        'cessionStatus',
      ]
    }
  />
);

export default PendingConfirming;
