import { ConfirmingPortfolio } from '@fingo/lib/views';
import React from 'react';

const CurrentConfirming = () => (
  <ConfirmingPortfolio
    viewTitle="Nóminas vigentes"
    queryCustomVariables={
      { payrollStatus: 'completed',
        status_Status: 'INITIATED' }
    }
    includeHeaders={
      ['id',
        'createdAt',
        'totalAmount',
        'numberOfOperations',
        'numberOfDocuments',
        'currentActions',
      ]
    }
  />
);

export default CurrentConfirming;
