import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { FingoPaper } from '@fingo/lib/components/layout';
import { SignDocument, SuccessDocumentSigning } from './orderingRoutes';
import { orderingTabs } from '../../../routes/sales';
import { useCountOrdering } from '../../../hooks';

const Ordering = () => {
  const { path: routePath } = useRouteMatch();
  const {
    available,
    offers,
    activeOperations,
    finishedOperations,
  } = useCountOrdering();
  const tabs = orderingTabs.map((tab, idx) => ({
    ...tab,
    quantity: [available, offers, activeOperations, finishedOperations][idx],
  }));
  return (
    <Switch>
      <Route path={`${routePath}/signdocument/success`}>
        <FingoPaper
          tabs={[
            {
              id: 'success-document-signing',
              label: 'Éxito mandato',
              path: 'success',
              component: <SuccessDocumentSigning />,
              disabled: false,
              show: true,
            },
          ]}
        />
      </Route>
      <Route path={`${routePath}/signdocument`}>
        <FingoPaper
          tabs={[
            {
              id: 'sign',
              label: 'Firma de mandato',
              path: 'sign',
              component: <SignDocument />,
              disabled: false,
              show: true,
            },
          ]}
        />
      </Route>
      <Route>
        <FingoPaper tabs={tabs} />
      </Route>
    </Switch>
  );
};

export default Ordering;
