import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import { Grid, Typography, Box } from '@mui/material';
import ConfirmingCreditLine from '@fingo/lib/views/confirming/columns/views/ConfirmingCreditLine';
import FingoDashBoardBox from './DashBoardBox';
import ConfirmingSupplierChart from './ConfirmingSupplierGraph';
import ConfirmingOperationSummaryChart from './ConfirmingOperationSummaryChart';
import NextConfirmingExpirations from './NextConfirmingExpirations';
import ConfirmigPayrollSummaryAnalytics from './ConfirmigPayrollSummaryAnalytics';

const ConfirmingDashboard = () => (
  <>
    <PaperHeader viewTitle="¡Bienvenido!" />
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
          Resumen financiero
        </Typography>
        <FingoDashBoardBox
          title="Mi línea"
          height={180}
        >
          <ConfirmingCreditLine
            height="100%"
            width="100%"
            justifyContent="center"
          />
        </FingoDashBoardBox>
      </Grid>

      <Grid item xs={12} md={7}>
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
          Resumen operativo
        </Typography>
        <NextConfirmingExpirations />
      </Grid>

      <Grid container item xs={12} spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={6} md={2.5}>
          <Box display="flex" flexDirection="column" height="100%" minHeight={140}>
            <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
              Proveedores vigentes financiados
            </Typography>
            <FingoDashBoardBox title="" flex={1}>
              <ConfirmingSupplierChart />
            </FingoDashBoardBox>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={2.5}>
          <Box display="flex" flexDirection="column" height="100%">
            <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
              Montos
            </Typography>
            <FingoDashBoardBox title="" flex={1}>
              <ConfirmigPayrollSummaryAnalytics />
            </FingoDashBoardBox>
          </Box>
        </Grid>

        <Grid item xs={12} md={7}>
          <Box display="flex" flexDirection="column" height="100%">
            <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
              Próximos vencimientos por cobrar
            </Typography>
            <ConfirmingOperationSummaryChart />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  </>
);
export default ConfirmingDashboard;
