import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import { useGetUser } from '@fingo/lib/hooks';
import { salesTabs } from '../../../routes/sales';

const Sales = () => {
  const currentUser = useGetUser();
  const tabs = currentUser.enabledSupplierPortals.length ? salesTabs : [salesTabs[0]];
  return <FingoPaper tabs={tabs} />;
};

export default Sales;
