import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const NoDocumentOffersError = ({ document }) => (
  <>
    <Typography variant="h4" paragraph>
      No posees {document} con oferta disponibles
    </Typography>
    <Typography variant="body1" paragraph>
      Revisa {' '}
      <Link
        component={RouterLink}
        to={document === 'facturas' ? ('/app/sales/factoring/available') : ('/app/sales/ordering/available')}
      >tus {document} disponibles
      </Link>
      {' '} para enviar a evaluar.
    </Typography>
  </>
);

NoDocumentOffersError.propTypes = {
  document: PropTypes.string,
};

NoDocumentOffersError.defaultProps = {
  document: 'facturas',
};
export default NoDocumentOffersError;
