import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import { FakeDocumentList } from '@fingo/lib/components/lists';
import { useBooleanState } from '@fingo/lib/hooks';
import { useFakeInvoicePreColumns, HEADERS, MOBILE_HEADERS } from '@fingo/lib/constants';
import PropTypes from 'prop-types';
import MySalesTourGuide from '../../../components/joyRides/MySalesTourGuide';

const MySales = ({ registerDialog, setRegisterDialog }) => {
  const [openJoyride, toggleJoyride] = useBooleanState(false);
  return (
    <>
      <FakeDocumentList
        type="my-sales"
        headerTitle="Cuentas por cobrar"
        includeHeaders={HEADERS}
        mobileHeaders={MOBILE_HEADERS}
        preColumns={useFakeInvoicePreColumns()}
        openJoyride={toggleJoyride}
        registerDialog={registerDialog}
        setRegisterDialog={setRegisterDialog}
      />
      <MySalesTourGuide run={openJoyride} setRun={toggleJoyride} />
    </>
  );
};
MySales.propTypes = {
  registerDialog: PropTypes.bool.isRequired,
  setRegisterDialog: PropTypes.func.isRequired,
};
const Sales = ({ registerDialog, setRegisterDialog }) => (
  <FingoPaper
    tabs={[
      {
        id: 'my-sales',
        label: 'Cuentas por cobrar',
        path: 'my-sales',
        // eslint-disable-next-line max-len
        component: <MySales registerDialog={registerDialog} setRegisterDialog={setRegisterDialog} />,
        disabled: false,
        show: true,
      },
    ]}
  />
);

Sales.propTypes = {
  registerDialog: PropTypes.bool.isRequired,
  setRegisterDialog: PropTypes.func.isRequired,
};

export default Sales;
