import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const NoDocumentError = ({ document }) => (
  <>
    <Typography variant="h4" paragraph>
      No tiene {document === 'invoice' ? 'facturas' : 'documentos'} disponibles para mostrar
    </Typography>
    <Typography variant="body1" paragraph>
      Aquí se mostrarán tus {document === 'invoice' ? 'facturas' : 'documentos'} cuando estén disponibles.
    </Typography>
  </>
);

NoDocumentError.propTypes = {
  document: PropTypes.string,
};

NoDocumentError.defaultProps = {
  document: 'document',
};
export default NoDocumentError;
