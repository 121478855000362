import React from 'react';
import { ConfirmingPortfolio } from '@fingo/lib/views';
import ConfirmingUploadActions from '@fingo/lib/views/confirming/columns/views/ConfirmingUploadActions';
import { formatGraphQlDatetime } from '@fingo/lib/helpers';
import moment from 'moment';

const ConfirmingUpload = () => (
  <ConfirmingPortfolio
    Actions={ConfirmingUploadActions}
    queryCustomVariables={{ status_Status: 'PENDING', createdAt_Gte: formatGraphQlDatetime(moment().subtract(2, 'weeks')) }}
  />
);

export default ConfirmingUpload;
