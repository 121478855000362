/* eslint-disable react/prop-types */
import { useQuery } from '@apollo/client';
import SelectableButtonGroup from '@fingo/lib/components/buttons/SelectableButtonGroup';
import { CONFIRMING_INVOICE_EXPIRAION_ANALYTICS } from '@fingo/lib/graphql';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Stack, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from 'recharts';
import { formatMoney } from '@fingo/lib/helpers';
import { useIsMobile } from '@fingo/lib/hooks';
import FingoDashBoardBox from './DashBoardBox';

const CustomTick = ({ x, y, payload, groupBy, data, isMobile }) => {
  const monthFormat = isMobile ? 'MMM' : 'MMMM';
  const yearFormat = isMobile
    ? `'${moment().month(data[payload.index].period_value - 1).format('YY')}`
    : moment().month(data[payload.index].period_value - 1).format('YYYY');

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        textAnchor="middle"
        y="8%"
      >
        {
        groupBy === 'week'
          ? data[payload.index].period
          : capitalize(moment().month(data[payload.index].period_value - 1).format(monthFormat))
        }
      </text>
      <text
        textAnchor="middle"
        y="18%"
        fill="gray"
      >
        {groupBy === 'week'
          ? '' : yearFormat}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: 'white', borderRadius: 5 }}>
        <p className="desc">{`$${formatMoney(payload[0].value)}`}</p>
      </div>
    );
  }
  return null;
};

const ConfirmingOperationSummaryChart = () => {
  const [variables, setVariables] = useState({
    expirationDateGte: moment().subtract(3, 'months'),
    expirationDateLte: moment().add(3, 'months'),
    groupBy: 'month',
  });
  const { data } = useQuery(CONFIRMING_INVOICE_EXPIRAION_ANALYTICS, { variables });
  const result = useMemo(() => data?.confirmingInvoiceExpirationAnalytics || [{ period_value: '', period: 'Cargando' }], [data]);
  const setSelector = useCallback((groupBy) => () => setVariables((old) => (
    { ...old,
      groupBy,
      expirationDateGte: moment().subtract(3, `${groupBy}s`),
      expirationDateLte: moment().add(3, `${groupBy}s`),
    })), []);
  const formatToMM = (value) => `${(value / 1000000).toFixed(0)}MM`;
  const isMobile = useIsMobile('sm');

  return (
    <FingoDashBoardBox title={(
      <Stack direction="row" justifyContent="space-between" mx={2}>
        <Typography align="left"> Próximos vencimientos por cobrar</Typography>
        <SelectableButtonGroup
          mb={1}
          buttonProps={{ size: 'small', sx: { maxHeight: '24px', fontSize: '12px', m: 0.4 } }}
          buttonInfo={[
            {
              id: 'month',
              label: 'Meses',
              callback: setSelector('month'),
            },
            {
              id: 'week',
              label: 'Semanas',
              callback: setSelector('week'),
            },
          ]}
        />
      </Stack>
            )}
    >
      <Stack height="144px" direction="row">
        <IconButton sx={{ borderRadius: 0 }} size="small">
          <ArrowBackIos fontSize={isMobile ? 'small' : 'medium'} />
        </IconButton>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={100}
            height={300}
            data={result}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <Tooltip content={<CustomTooltip />} />
            <XAxis
              dataKey="week_number"
              tick={
              (props) => (
                <CustomTick
                  {...props}
                  groupBy={variables.groupBy}
                  data={result}
                  isMobile={isMobile}
                />
              )
              }
            />
            <YAxis width={32} tickFormatter={formatToMM} />
            <Bar dataKey="total_amount" fill="#E62E66" barSize={48} />
          </BarChart>
        </ResponsiveContainer>
        <IconButton sx={{ borderRadius: 0 }} size="small">
          <ArrowForwardIos fontSize={isMobile ? 'small' : 'medium'} />
        </IconButton>
      </Stack>
    </FingoDashBoardBox>
  );
};

ConfirmingOperationSummaryChart.propTypes = {
  loading: PropTypes.bool.isRequired,
  confirmingInvoiceExpirationAnalytics: PropTypes.shape({
    totalAmountFunded: PropTypes.number,
    totalInterestAmount: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.objectOf(PropTypes.any),
  setVariables: PropTypes.func,
};

ConfirmingOperationSummaryChart.defaultProps = {
  confirmingInvoiceExpirationAnalytics: {},
  variables: {},
  setVariables: () => {},
};

export default ConfirmingOperationSummaryChart;
