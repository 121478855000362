import React from 'react';
import { AVAILABLE_FACTORING } from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import { useReactiveVar } from '@apollo/client';
import { currencyVar } from '@fingo/lib/apollo/reactive-variables';
import { evaluatingIssuedGte } from '../../../../constants';
import NoEvaluatingError from './noDataMessages/NoEvaluatingError';

const EvaluationInvoice = () => {
  const currency = useReactiveVar(currencyVar);

  return (
    <DocumentList
      trackerId="OFFERS"
      type="available-offers"
      headerTitle="Evaluaciones"
      queryDocument={AVAILABLE_FACTORING}
      emitter
      includeHeaders={[
        'folio',
        'dteType_Code',
        'dateIssued',
        'receiver_sortable',
        'amountWithIva',
        'preoffer_MonthlyRate',
        'preoffer_Preofferevaluationrequest_Status',
      ]}
      mobileHeaders={[
        'folio',
        'receiver_sortable',
        'preoffer_MonthlyRate',
        'defaultRate',
        'amountWithIva',
      ]}
      customVariables={{
        currency,
        preoffer_Preofferevaluationrequest_Status_In: ['Evaluating', 'Rejected'],
        dateIssued_Gte: evaluatingIssuedGte,
        dateIssued_Lte: null,
      }}
      preColumns={useInvoicePreColumns()}
      noRowsMessage={NoEvaluatingError}
      showFilters
      defaultFilterProps={{
        showValidSiiCredentialsFilter: false,
        showLightningFilter: false,
        showPreoffersFilters: false,
        showStatesFilter: false,
        showDatesFilter: false,
        showRefresh: true,
        defaultFilterDays: null,
        showCompanyIssuerFilter: false,
        showQuickFilters: true,
        buttonsToShow: ['evaluationRejected', 'evaluationInProcess'],
      }}
    />
  );
};

export default EvaluationInvoice;
