import { ConfirmingPortfolio } from '@fingo/lib/views';
import React from 'react';

const HistoricalConfirming = () => (
  <ConfirmingPortfolio
    viewTitle="Nóminas históricas"
    queryCustomVariables={{
      payrollStatus: 'finished',
      status_Status: 'COMPLETED',
    }}
    includeHeaders={
      ['id',
        'createdAt',
        'totalAmount',
        'numberOfOperations',
        'numberOfDocuments',
        'historicalActions',
      ]
    }
  />
);

export default HistoricalConfirming;
