import React from 'react';
import { IconButton, Menu, Stack } from '@mui/material';
import { MY_SALES_PURCHASES } from '@fingo/lib/graphql';
import { AnchorElType } from '@fingo/lib/propTypes/AnchorElType';
import PropTypes from 'prop-types';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import Close from '@mui/icons-material/Close';

const PayrollQuickProfile = ({ anchorEl, setAnchorEl, companyId }) => {
  const open = Boolean(anchorEl);
  if (!companyId) return <></>;
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: { width: '60%', height: '50%', px: 2 },
      }}
    >
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <IconButton sx={{ alignSelf: 'end' }} onClick={() => setAnchorEl(null)}>
          <Close />
        </IconButton>
      </Stack>
      <DocumentList
        trackerId="CONFIRMING_INVOICES"
        type="confirming-invoices"
        CustomHeader={() => <></>}
        queryDocument={MY_SALES_PURCHASES}
        includeHeaders={[
          'folio',
          'dteType_Code',
          'dateIssued',
          'company_MasterEntity_Name',
          'amountWithIva',
        ]}
        mobileHeaders={[
          'folio',
          'company_MasterEntity_Name',
          'amountWithIva',
        ]}
        preColumns={useInvoicePreColumns()}
        customVariables={
          {
            companyId,
            dateIssued_Gte: null,
            dateIssued_Lte: null,
            relatedConfirming_Status_In: ['Transfered'],
          }
        }
      />
    </Menu>
  );
};

PayrollQuickProfile.propTypes = {
  anchorEl: AnchorElType,
  companyId: PropTypes.number,
  setAnchorEl: PropTypes.func,
};

PayrollQuickProfile.defaultProps = {
  anchorEl: null,
  companyId: null,
  setAnchorEl: () => {},
};

export default PayrollQuickProfile;
